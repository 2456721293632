@import "~bootswatch/dist/flatly/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/flatly/bootswatch";

// Extend bootstrap utilities: https://getbootstrap.com/docs/5.2/utilities/api/#using-the-api
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

$utilities: map-merge($utilities,
		( //Existing utilities
			"top": map-merge(map-get($utilities, "top"),
				(values: map-merge(map-get(map-get($utilities, "top"), "values"),
						(5: 5%),
					),
				),
			),
			"end": map-merge(map-get($utilities, "end"),
				(values: map-merge(map-get(map-get($utilities, "end"), "values"),
						(5: 5%),
					),
				),
			),

			//New utilities
			"z-index": (property: z-index,
				class: z,
				values: (10: 10,
					1100: 1100,
				),
			),
		));
@import '~bootstrap/scss/bootstrap';

@import url('https://fonts.googleapis.com/css?family=Inter&display=swap');

html,
body {
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	overflow-x: hidden;
}

hr {
	border-color: $secondary;
}

body {
	font-family: Inter !important;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
}

.input-group-text {
	background-color: #2C3E50 !important;
	color: white !important;
}

.sign-in-container {
	height: 100%;
	display: flex;
	align-items: center;
}

.form-signin {
	max-width: 330px;
	padding: 15px;
}

.print_text {
	font-size: 12px;
}

.form-signin .form-floating:focus-within {
	z-index: 2;
}

.form-signin input[type="email"] {
	margin-bottom: -1px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
	margin-bottom: 10px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

td.dt-collapse {
	max-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.smooth-caret {
	transition: transform 0.3s ease;
}

a {
	font-weight: bold !important;
}

td.dt-collapse {
	max-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.notifications {
	position: fixed;
	right: 5%;
	top: 5%;
	z-index: 9999;
	display: flex;
	flex-flow: column nowrap;
}

.input-wrapper {
	display: inline-block !important;
	margin-right: 10px !important;
}

.slide-transition {
	transition: max-height 0.5s ease-in-out !important;
	overflow: hidden !important;
}

.slide-hidden {
	max-height: 0 !important;
}

.border-around-screen {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 2px solid red;
	z-index: 9999;
	pointer-events: none;
}

.footer-sticky {
	margin-top: auto;
}

.navbar-nav .nav-link {
	padding-right: 10 !important;
	padding-left: 10 !important;
}

.quest_hide {
	display: none;
}

.home_hide {
	display: none;
}

.doc_hide {
	display: none;
}

.cal_hide {
	display: none;
}

.payer_hide {
	display: none;
}

.prov_hide {
	display: none;
}

.staff_hide {
	display: none;
}

.memb_hide {
	display: none;
}

.qcont_hide {
	display: none;
}

.file {
	padding: 5px;
}

.insert_container {
	margin-top: 10px;
}

.bd-placeholder-img {
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}


@media (min-width: 768px) {
	.bd-placeholder-img-lg {
		font-size: 3.5rem;
	}
}

@media print {
	footer {
		page-break-after: always;
	}
}

.b-example-divider {
	height: 3rem;
	background-color: rgba(0, 0, 0, .1);
	border: solid rgba(0, 0, 0, .15);
	border-width: 1px 0;
	box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
	flex-shrink: 0;
	width: 1.5rem;
	height: 100vh;
}

.bi {
	vertical-align: -.125em;
	fill: currentColor;
}

.nav-scroller {
	position: relative;
	z-index: 2;
	height: 2.75rem;
	overflow-y: hidden;
}

.nav-scroller .nav {
	display: flex;
	flex-wrap: nowrap;
	padding-bottom: 1rem;
	margin-top: -1px;
	overflow-x: auto;
	text-align: center;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
}

.text-larger {
	font-size: 1.5rem !important;
}

.dropdown-toggle {
	outline: 0;
}

.btn-toggle {
	padding: .25rem .5rem;
	font-weight: 600;
	color: rgba(0, 0, 0, .65);
	background-color: transparent;
}

.btn-toggle:hover,
.btn-toggle:focus {
	color: rgba(0, 0, 0, .85);
}

.btn-toggle::before {
	width: 1.25em;
	line-height: 0;
	content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
	transition: transform .35s ease;
	transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"] {
	color: rgba(0, 0, 0, .85);
}

.btn-toggle[aria-expanded="true"]::before {
	transform: rotate(90deg);
}

.btn-toggle-nav a {
	padding: .1875rem .5rem;
	margin-top: .125rem;
	margin-left: 1.25rem;
}

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
	background-color: #d2f4ea;
}

.scrollarea {
	overflow-y: auto;
}

#loading-screen {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: rgba(255, 255, 255, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
}

.custom-header {
	color: white !important;
	background-color: $primary !important;
}

// .sticky {
// 	position: sticky !important;
// 	top: 0 !important;
// 	background: $primary !important;  /* you might need to change this color based on your design */
// 	z-index: 10 !important;  /* this is needed so the header stays on top of the other rows */
// }

.mce-content-body::after {
	content: "" !important;
	display: block !important;
	height: 150px !important;
}

.custom-footer {
	position: absolute !important;
	bottom: 0 !important;
	width: 100% !important;
	text-align: center !important;
}

// #quickbooks_connection {
// 	position: absolute !important;
// 	bottom: 20px !important;
// 	right: 20px !important;
// 	background-color: $primary;
// }

#quickbooks_connection {
	transition: transform 0.3s ease;
	display: inline-block;
	background-color: $success;
	color: #fff;
	padding: 9px 15px !important;
	border-radius: 4px;
	font-size: 12px;
	text-decoration: none;
	position: absolute;
	top: 10rem;
	right: 4rem;
	z-index: 100;
	// border-radius: 50%;
}

#quickbooks_connection:hover {
	background-color: $success;
	transform: scale(1.1);
	color: white;
}

.input-hint {
	font-size: 1em;
}

.icon-btn {
	display: inline-flex !important;
	justify-content: center !important;
	align-items: center !important;
	width: 25px !important;
	height: 25px !important;
	border-radius: 50% !important;
	background-color: transparent !important;
	transition: background-color 0.3s ease !important;
}

.icon-btn-lg {
	display: inline-flex !important;
	justify-content: center !important;
	align-items: center !important;
	width: 30px !important;
	height: 30px !important;
	border-radius: 50% !important;
	background-color: transparent !important;
	transition: background-color 0.3s ease !important;
}

.icon-btn-xl {
	display: inline-flex !important;
	justify-content: center !important;
	align-items: center !important;
	width: 35px !important;
	height: 35px !important;
	border-radius: 50% !important;
	background-color: transparent !important;
	transition: background-color 0.3s ease !important;
}

#payerSyncHelp {
	color: $primary !important;
}

#payerSyncHelp:hover {
	color: white !important;
}

.icon-btn:hover {
	background-color: $primary !important;
	color: white !important;
	cursor: pointer;
}

.offcanvas.offcanvas-top {
	height: 100vh !important;
}

.icon-btn i {
	transition: color 0.3s ease !important;
}

.icon-btn:hover i {
	color: white !important;
	cursor: pointer;
}

.icon-btn-lg:hover {
	background-color: $primary !important;
	color: white !important;
	cursor: pointer;
}

.icon-btn-lg i {
	transition: color 0.3s ease !important;
}

.icon-btn-lg:hover i {
	color: white !important;
}

.icon-btn-xl:hover {
	background-color: $primary !important;
	color: white !important;
}

.icon-btn-xl i {
	transition: color 0.3s ease !important;
}

.icon-btn-xl:hover i {
	color: white !important;
}

//rotate + icon to x
.fa-plus {
	display: inline-block;
	transition: transform 1s ease-in-out;
}

.rotate {
	transform: rotate(45deg);
}

.custom-col {
	max-width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.custom-input {
	// max-width: 80% !important;
	width: 100px !important;
}

.column-padding {
	margin-right: 150px !important;
	/* adjust as needed */
}

.column-padding-left {
	margin-left: 150px !important;
	/* adjust as needed */
}

.dot {
	height: 12px;
	width: 12px;
	border-radius: 50%;
}

#settingContent>* {
	flex-grow: 1;
}

//highlight top nav on page click
#links .nav-link.active {
	color: $teal !important;
}

.close {
	color: #fff !important;
}

.close:hover {
	color: #fff !important;
}

#confetti-canvas {
	position: fixed !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
	pointer-events: none !important;
	/* So it doesn't block clicks */
	z-index: 1060 !important;
	/* A value higher than the Bootstrap modal */
}

.icon-btn {
	display: inline-flex !important;
	justify-content: center !important;
	align-items: center !important;
	width: 25px !important;
	height: 25px !important;
	border-radius: 50% !important;
	background-color: transparent !important;
	transition: background-color 0.3s ease !important;
	text-decoration: none !important;
	color: $primary !important;
}

.icon-btn:hover {
	background-color: $primary !important;
	color: white !important;
}

#sortableList .list-group-item {
	cursor: grab !important;
}

#sortableList .list-group-item:active {
	cursor: grabbing !important;
}

.loading-input {
	position: relative;
	/* Other styling as needed */
}

.loading-input.loading::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 2px solid transparent;
	border-top: 2px solid blue;
	border-radius: 5px;
	animation: rotate-loading 1s linear infinite;
}

@keyframes rotate-loading {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.equal-width-table {
	table-layout: fixed;
	width: 50%;
}

.equal-width-table td {
	width: 50%;
}

@import '~datatables.net-bs5';
@import '~datatables.net-buttons-bs5';